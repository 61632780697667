import {Component} from "preact";

function LeftArrow(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" className={props.className} fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={this.props.onClick}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
  </svg>;
}

function RightArrow(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" className={props.className} fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={this.props.onClick}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>;
}

function CollapseArrow(props) {
  return <svg className={props.className} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={this.props.onClick}>
    <path d="m2.1359 16.45c-0.94281 0.94281 0.4714 2.357 1.4142 1.4142l3.9499-3.9499v1.8286c0.02277 1.3102 1.9769 1.3102 1.9997 0v-4.2426c-8.4e-5 -0.55217-0.44768-0.99977-0.99985-0.99985h-4.2426c-1.3565-0.02358-1.3565 2.0233 0 1.9997h1.8286z"/>
    <path d="m17.864 3.5501c0.94282-0.94281-0.47141-2.357-1.4142-1.4142l-3.9499 3.9499v-1.8286c0.02358-1.3565-2.0233-1.3565-1.9997 0v4.2426c8.3e-5 0.55217 0.44769 0.99976 0.99986 0.99985h4.2427c1.3102-0.02277 1.3102-1.9769 0-1.9997h-1.8286z"/>
  </svg>;
}

export {LeftArrow, RightArrow, CollapseArrow};