function snakeToCamel(dict) {
  let camel;
  if (Array.isArray(dict)) {
    camel = [];
    for (let i=0;i<dict.length;i++) {
      camel[i] = snakeToCamel(dict[i]);
    }
    return camel;
  } else {
    camel = {};
    for (let key of Object.keys(dict)) {
      let newKey = key;
      let words = key.split("_");
      if (words.length > 1) {
        newKey = words[0];
        for (let i=1;i<words.length;i++) {
          newKey = newKey + words[i].substring(0, 1).toUpperCase() + words[i].substring(1);
        }
      }
      let newValue = dict[key];
      if (typeof dict[key] === 'object' ) {
        newValue = snakeToCamel(dict[key]);
      }
      camel[newKey] = newValue;
    }
    return camel;
  }

}

function camelToSnake(dict) {
  let camel = {};
  for (let key of Object.keys(dict)) {
    let newKey = "";
    let lastLowercase = true;
    let capsFix = false;
    for(let i=0;i<key.length;i++) {
      if (key[i] === key[i].toUpperCase()) {
        if (lastLowercase) {
          if (i === 0) {
            newKey = newKey + key[i];
            capsFix = true;
          } else {
            newKey = newKey + "_" + key[i].toLowerCase();
          }
        } else {  // Fix all caps words
          if (capsFix) {
            newKey = newKey + key[i];
          } else {
            newKey = newKey.substring(0, newKey.length - 1) + newKey.substring(newKey.length - 1).toUpperCase() + key[i];
            capsFix = true;
          }
        }
        lastLowercase = false;
      } else {
        newKey = newKey + key[i];
        lastLowercase = true;
        capsFix = false;
      }
    }
    let newValue = dict[key];
    if (typeof dict[key] === 'object' ) {
      newValue = camelToSnake(dict[key]);
    }
    camel[newKey] = newValue;
  }
  return camel;
}

export {snakeToCamel, camelToSnake};