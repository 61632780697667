import {camelToSnake, snakeToCamel} from "./utils";

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

async function getCSRF() {
  let csrf = getCookie('csrftoken');
  if (csrf == null) {
    await simpleAPIGet("get_csrf");
    return getCookie('csrftoken');
  }
  return csrf;
}


function simpleAPIGet(url, params) {
  let p = new Promise(function (resolve, reject) {
    let fullURL = url;
    if (params) {
      let urlParams = new URLSearchParams(params);
      fullURL = `${url}?${urlParams.toString()}`
    }
    fetch(fullURL).then(r => r.text()).then(r => {
      let data;
      try {
        data = snakeToCamel(JSON.parse(r));
      } catch (e) {
        data = r;
      }
      resolve(data);
    });
  });
  return p;
}


function post(url, params) {
  let p = new Promise(async function (resolve, reject) {
    // let urlParams = new URLSearchParams(params);
    let csrf = await getCSRF();
    let options = {
      method: 'POST',
      headers: {'X-CSRFToken': csrf}
    };
    if (params) {
      options['body'] = JSON.stringify(camelToSnake(params));
    }
    fetch(url, options).then(r => r.json()).then(r => resolve(snakeToCamel(r)));
  });
  return p;
}

function getMusicCategories() {
  return simpleAPIGet("/projects/music/categories");
}

function getMusicPieces() {
  return simpleAPIGet("/projects/music/pieces");
}

function getMusicDescription(ID) {
  return simpleAPIGet(`/projects/music/description-${ID}`);
}

function getCodeCategories() {
  return simpleAPIGet("/projects/code/categories");
}

function getCodeProjects() {
  return simpleAPIGet("/projects/code/projects");
}

function getCodeDescription(ID) {
  return simpleAPIGet(`/projects/code/description-${ID}`);
}


export {getMusicCategories, getMusicDescription, getMusicPieces,
  getCodeCategories, getCodeDescription, getCodeProjects};