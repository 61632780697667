import {Component} from 'preact';
import {wrapGrid} from "animate-css-grid";
// import {wrapGrid} from "../animategrid/main";
import "preact/debug";
import "preact/devtools";
import styles from "../../scss/code.scss";
import {animate, easeInOut} from "popmotion";
import {getCodeDescription, getCodeProjects} from "../api";
import TestText from "../music/testText";
import {CollapseArrow, LeftArrow, RightArrow} from "./buttons";

const CLICK_SCROLL_SETTINGS = {behavior: "smooth", block: "nearest", inline: "center"}

let genData = (len) => {
  let data = [];
  for (let i=0; i<len; i++) {
    data.push({
      "title": `Test Project ${i}`,
      "year": "2022",
      "miniDescription": "Project mini description",
      "tech": "Tech stack"
    })
  }
  return data;
}


class Code extends Component {
  state = {
    rows: 3,
    expanded: -1,
    lastExpanded: -1,
    size: 10,
    gutter: 2,
    data: [],
    animating: false,
    lastScroll: 0
  }

  ref = null;
  scrollRef = null;
  setRef = (dom) => this.ref = dom;
  scrollAnimation = null;

  constructor() {
    super();
    this.dragScroll = this.dragScroll.bind(this);
    this.resize = this.resize.bind(this);
    this.expand = this.expand.bind(this);
    this.gridSetup = this.gridSetup.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
  }

  gridSetup(ref) {
    this.gridRef = ref
    wrapGrid(this.gridRef, {
      stagger: 0,
      duration: 800,
      easing: "easeInOut",
      onStart: (elems) => {
        this.setState({animating: true});
        if (this.state.expanded == -1) {
          // Check if we need to scroll on close
          elems.forEach((elem) => {
            if (elem.dataset.id == this.state.lastExpanded) {
              if ((elem.offsetLeft + elem.offsetWidth) < this.gridRef.scrollLeft) {
                console.log("scrolling to avoid confusion");
                this.scrollRef.scrollTo({left: elem.offsetLeft})
                this.scrollTo(elem.offsetLeft + (elem.offsetWidth / 2) - (this.scrollRef.offsetWidth / 2), 600)
              } else {
                let from = this.state.lastScroll;
                this.scrollRef.scrollLeft = from;
                this.scrollTo(elem.offsetLeft + (elem.offsetWidth / 2) - (this.scrollRef.offsetWidth / 2), 800, from);
              }
            }
          })
        } else {
          elems.forEach((elem) => {
            if (elem.dataset.id == this.state.expanded) {
              this.scrollTo(elem.offsetLeft + (elem.offsetWidth / 2) - (this.scrollRef.offsetWidth / 2), 800);
            }
          })
        }
      },
      onEnd: (elems) => {
        this.setState({animating: false})
      }
    });
  }

  dragScroll(ref) {
    let isDown = false;
    let startX;
    let scrollLeft;
    this.scrollRef = ref;
    this.isScrolling = false;



    this.scrollRef.addEventListener('mousedown', (e) => {
      e.preventDefault();
      isDown = true;
      startX = e.pageX - this.scrollRef.offsetLeft;
      scrollLeft = this.scrollRef.scrollLeft;
    });
    this.scrollRef.addEventListener('mouseleave', () => {
      isDown = false;
      setTimeout(() => this.isScrolling = false, 100);
    });
    this.scrollRef.addEventListener('mouseup', () => {
      isDown = false;
      setTimeout(() => this.isScrolling = false, 100);
    });
    this.scrollRef.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - this.scrollRef.offsetLeft;
      const walk = (x - startX) * 2.5;
      this.scrollRef.scrollLeft = scrollLeft - walk;
      this.isScrolling = true;
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.getData();
  }

  getData() {
    getCodeProjects().then((data) => {
      let moreData = genData(30);
      // this.setState({data: data.concat(moreData)})
      this.setState({data: data})
    })
  }

  resize() {
    let isMobile = this.ref.clientWidth < 768;
    let size = isMobile ? 10 : 12;
    let gutter = isMobile ? 2 : 4;
    let val = size + gutter + 1;
    this.setState({
      rows: Math.floor(this.ref.clientHeight / (val * 17)),
      size: size,
      gutter: gutter
    });
  }

  scrollTo(to, duration, from) {
    if (this.scrollAnimation) {
      this.scrollAnimation.stop();
    }
    if (from === undefined) {
      from = this.scrollRef.scrollLeft;
    }
    this.scrollAnimation = animate({
      from: from,
      to: to,
      ease: easeInOut,
      duration: duration,
      onUpdate: latest => this.scrollRef.scrollTo({left: latest}),
      onStop: () => {this.scrollAnimation = null}
    })
  }

  expand(i) {
    if (!this.isScrolling) {
      this.setState((state) => {return {expanded: i, lastExpanded: i == -1 ? state.lastExpanded : i, lastScroll: this.scrollRef.scrollLeft}});
    }
  }

  scrollRight() {
    let current = this.scrollRef.scrollLeft + this.scrollRef.offsetWidth;
    // console.log("Staring at", current)
    let gridChildren = this.gridRef.children;
    let elem;
    let didExpandOffset = false;
    for (let i=0; i<gridChildren.length; i+=this.state.rows) {
      if (i >= this.state.expanded && this.state.expanded != -1 && !didExpandOffset) {
        // console.log("setting i to expanded");
        i = this.state.expanded;
        didExpandOffset = true;
      }
      // console.log(i, gridChildren[i].offsetLeft + gridChildren[i].offsetWidth);
      let expandedElem = i == this.state.expanded;
      if ((gridChildren[i].offsetLeft + gridChildren[i].offsetWidth) > current) {
        let newIndex = expandedElem ? i + 1 : i + this.state.rows;
        // console.log("scrolling to", newIndex)
        elem = gridChildren[Math.min(newIndex, gridChildren.length - 1)];
        break;
      }
      if (expandedElem) {
        i = i - (this.state.rows - 1);
      }
    }
    if (!elem) {
      elem = gridChildren[gridChildren.length - 1];
    }
    this.scrollTo(elem.offsetLeft + elem.offsetWidth - this.scrollRef.offsetWidth, 700);
  }

  scrollLeft() {
    let current = this.scrollRef.scrollLeft;
    // console.log("Staring at", current)
    let gridChildren = this.gridRef.children;
    let elem;
    let didExpandOffset = false;
    for (let i=gridChildren.length - 1; i>0; i-=this.state.rows) {
      if (i <= this.state.expanded && this.state.expanded != -1 && !didExpandOffset) {
        // console.log("setting i to expanded");
        i = this.state.expanded;
        didExpandOffset = true;
      }
      // console.log(i, gridChildren[i].offsetLeft);
      let expandedElem = i == this.state.expanded;
      if (gridChildren[i].offsetLeft < current) {
        let newIndex = expandedElem ? i - 1 : i - this.state.rows;
        // console.log("scrolling to", newIndex)
        elem = gridChildren[Math.max(newIndex, 0)];
        break;
      }
      if (expandedElem) {
        i = i + (this.state.rows - 1);
      }
    }
    if (!elem) {
      elem = gridChildren[0];
    }
    this.scrollTo(elem.offsetLeft, 700);
  }

  render(props, state, context) {
    let blocks = this.state.data.map((data, i) => {
      return <ContentBlock key={i} index={i} ID={data.id} expand={this.expand} expanded={i == this.state.expanded}
                    title={data.title} miniDesc={data.miniDescription} year={data.year} tech={data.tech}/>;
    })
    let filler = [];
    for (let i=0; i<this.state.rows; i++) {
      filler.push(<div class={"filler"}></div>)
    }
    return <div ref={this.setRef} class={"ccontainer h-full w-full py-8 md:py-12 flex items-center relative"} style={{"--rows": this.state.rows, "--size": `${this.state.size}rem`, "--gutter": `${this.state.gutter}rem`}}>
      <div ref={this.dragScroll} class={"bg-gray-100 bg-opacity-20 h-full w-full flex items-center overflow-y-auto no-scrollbar cursor-grab active:cursor-grabbing"}>
      <div ref={this.gridSetup} class={"content-grid grid grid-flow-col auto-cols-max w-full mr-52" + (this.state.animating ? " animating " : "")}>
        {filler}
        {blocks}
        {filler}
      </div>
      </div>
      <LeftArrow className={"hidden md:block absolute top-1/2 transform-gpu -translate-y-1/2 left-2 h-12 w-12 cursor-pointer"} onClick={this.scrollLeft}/>
      <RightArrow className={"hidden md:block absolute top-1/2 transform-gpu -translate-y-1/2 right-2 h-12 w-12 cursor-pointer"} onClick={this.scrollRight}/>
    </div>;
  }
}

const SELECT_THRESHOLD = 10;

class ContentBlock extends Component {
  state = {
    description: null,
    selecting: false,
    bodySelecting: false,
    mouseDown: null
  }

  ref = null;
  setRef = (dom) => {
    this.ref = dom;
    this.ref.onresize = this.resize;
  };

  setParentRef = (ref) => {
    this.parentRef = ref;
    ref.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      this.setState({"mouseDown": {x: e.clientX, y: e.clientY}, "selecting": false, "bodySelecting": false});
    });
    ref.addEventListener('mouseup', (e) => {
      if (this.state.mouseDown !== null) {
        if (this.state.selecting) {
          e.stopPropagation();
          e.preventDefault();
        }
        this.setState({"mouseDown": null});
      }
    });
    ref.addEventListener('mousemove', (e) => {
      if (this.state.mouseDown !== null) {
        let dist = Math.sqrt((this.state.mouseDown.x - e.clientX) ** 2 + (this.state.mouseDown.y - e.clientY) ** 2);
        if (dist >= SELECT_THRESHOLD) {
          this.setState({selecting: true, bodySelecting: true});
        }
      }
    });
  }

  constructor() {
    super();
    this.click = this.click.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.bodyClick = this.bodyClick.bind(this);
  };

  componentDidMount() {
    // this.getDescription();
  }

  componentDidUpdate(previousProps, previousState, snapshot) {
    if (previousProps.expanded != this.props.expanded && this.props.expanded && this.state.description == null && this.gettingDescription != null) {
      this.getDescription();
    }
  }

  getDescription() {
    this.setState({description: ""});
    getCodeDescription(this.props.ID).then((data) => this.setState({description: data.descriptionMd}));
  }

  click() {
    if (!this.state.selecting) {
      if (this.props.expanded) {
        this.props.expand(-1);
      } else {
        this.getDescription();
        this.props.expand(this.props.index);
      }
    } else {
      // console.log("Eating click event");
      this.setState({"selecting": false})
    }
  }

  bodyClick(e) {
    if (!this.state.bodySelecting) {
      e.stopPropagation();
      if (!this.props.expanded) {
        this.getDescription();
        this.props.expand(this.props.index);
      }
    } else {
      // console.log("Eating click event");
      this.setState({"bodySelecting": false})
    }
  }

  render(props, state, context) {
    return <div data-id={this.props.index} onclick={this.click} ref={this.setParentRef}
                class={"content-block cursor-pointer w-full h-full " + (this.props.expanded ? "no-click-highligh expanded" : "")}>
      <div ref={this.setRef} class={"w-full h-full p-2 bg-red-100 bg-opacity-80 shadow-xl rounded-md overflow-hidden cursor-pointer"}>
        <div class={"relative flex flex-col w-full h-full overflow-hidden"}>
          <div class={"relative w-full"}><p class={"text-lg font-semibold font-display"}>{this.props.title}</p><CollapseArrow className={"collapse-icon w-6 h-6 absolute right-1 transform-gpu -translate-y-1/2 transition-opacity duration-500"}/></div>
          <p class={"text-base"}>{this.props.year}</p>
          <div onclick={this.bodyClick} class={"relative w-full h-full overflow-y-auto pb-6" + (this.props.expanded ? " no-click-highlight" : "") }>
            <p class={"absolute top-0 left-0 text-sm top-0 left-0 duration-500 transition-opacity overflow-hidden " + (this.props.expanded ? " opacity-0" : "")}>{this.props.miniDesc}</p>
            {this.props.expanded ? <div class={"prose overflow-y-auto transition-opacity duration-500 h-full w-full select-text animate-hide-scroll "  + (this.props.expanded ? "cursor-auto" : "opacity-0 pointer-events-none")} dangerouslySetInnerHTML={{__html: this.state.description}}>
              </div> : null}
          </div>
          <div
            class={"absolute bottom-0 left-0 whitespace-nowrap md:whitespace-normal truncate w-full overflow-hidden"}>{this.props.tech}</div>
        </div>
      </div>
    </div>;
  }
}


export default Code;